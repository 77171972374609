<template>
  <b-navbar id="navbarComponent" toggleable="lg" sticky class="navbar-dark row">
    <router-link :to="{ name: 'home' }" exact>
      <b-navbar-brand>
        <img src="@/assets/logo.png" />
        <img src="@/assets/esportslivescore.png" />
      </b-navbar-brand>
    </router-link>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto py-2 px-4">
        <Locale></Locale>

        <!--
        <b-nav-form>
          <b-form-input
            size="sm"
            class="mr-sm-2"
            placeholder="Search"
          ></b-form-input>
          <b-button size="sm" class="my-2 my-sm-0" type="submit"
            >Search</b-button
          >
        </b-nav-form>

        <b-nav-item-dropdown right>
          <template #button-content>
            <em>User</em>
          </template>
          <b-dropdown-item href="#">Profile</b-dropdown-item>
          <b-dropdown-item href="#">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>-->
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import Locale from "@/components/Locale.vue";

export default {
  components: {
    Locale,
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables";

#navbarComponent {
  height: 10vh;
  background-color: $primary;
  .navbar-toggler{
    padding: 5px;
  }
  .navbar-collapse {
    background-color: $primary;
  }
  //border-bottom: 2px solid $accent;
  a {
    color: $light-primary-text;
    img {
      height: 6vh;
    }
    @media (max-width: 760px) {
      img {
        height: 5vh;
      }
    }
    &.dropdown-item {
      color: $dark-primary-text;
    }
  }
}
</style>