<template>
  <div class="bubble d-none d-md-block">
    <strong class="mb-2">Lo sapevi che...</strong>
    <br />
    {{ funfact.fact }}
  </div>
</template>

<style lang="scss" scoped>
@import "@/sass/variables";

.bubble {
  margin: 15% 10%;
  position: relative;
  background: $accent;
  color: $dark-text;
  text-align: center;
  border-radius: 18px;
  padding: 15px;
  border: white solid 4px;
}
.bubble:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-width: 0 17px 33px 0;
  border-color: transparent $accent transparent transparent;
  bottom: -33px;
  left: 16%;
  margin-left: -8.5px;
}
.bubble:before {
  content: "";
  position: absolute;
  width: 0;
  z-index: 0;
  border-style: solid;
  border-width: 0 20px 36px 0;
  border-color: transparent white transparent transparent;
  bottom: -39px;
  left: 16%;
  margin-left: -8.5px;
  display: block;
}
</style>

<script>
import ajaxCall from "@/services/ajaxCall";
export default {
  data: () => {
    return {
      interval: null,
      funfact: "",
    };
  },
  mounted() {
    this.interval = setInterval(() => this.getFunfact(), 60 * 1000);
  },
  beforeDestroy() {
    if (this.interval != null) clearInterval(this.interval);
  },
  methods: {
    getFunfact() {
      ajaxCall.sendGet("/funfacts.json").then((response) => {
        this.funfact = this._.sample(response.data);
      });
    },
  },
};
</script>