<template>
  <div class="w-100 h-100 streaming-wrapper">
    <div
      v-if="Object.keys(mainEvent).length > 0 && mainEvent.is_live"
      style="height: 100vh"
    >
      <iframe
        :src="mainEvent.streaming_link /*channel_iframe_url*/"
        class="w-100"
        style="height: 100vh"
        frameBorder="0"
      ></iframe>
    </div>
    <div v-else class="empty">
      <img src="@/assets/logo.png" />
      <br />
      <span>L'evento live sta per iniziare...</span>
    </div>
  </div>
</template>
  
<script>
import api from "@/services/api";

const defaultType = "disciplina";
const defaultId = 1;
const defaultLimit = 1;

export default {
  data: () => {
    return {
      query: { [defaultType]: defaultId },
      mainEvent: {},
      interval: null,
    };
  },
  /*components: { Twitch },*/
  mounted() {
    this.query = this.$route.query;
    this.query.limite = defaultLimit;
    this.init();
    this.interval = setInterval(() => this.init(), 10 * 1000);
  },
  beforeDestroy() {
    if (this.interval != null) clearInterval(this.interval);
  },
  methods: {
    init() {
      if (this.type == "avvenimento")
        api.getAvvenimento(this.id, (res) => {
          this.mainEvent = res;
        });
      else
        api.getAvvenimenti(this.query, (data) => {
          if (data.length > 1) {
            this.mainEvent = data.shift();
          } else if (data.length == 1) {
            this.mainEvent = data[0];
          } else {
            this.mainEvent = {};
          }
        });
    },
  },
};
</script>
  
<style lang="scss" scoped>
@import "@/sass/variables";

.Catamaran {
  font-family: "Catamaran", sans-serif;
}

.streaming-wrapper {
  background-color: $primary-darker;
}

.empty {
  height: 100vh;
  color: white;
  font-size: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    height: 10vh;
  }
}
</style>
  
  