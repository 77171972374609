import Vue from "vue";
import VueRouter from "vue-router";

import Default from "@/layouts/Default.vue";
import FullContent from "@/layouts/FullContent.vue";

import Manifestazioni from "@/components/Manifestazioni.vue";
import Regolamenti from "@/components/Regolamenti.vue";
import RootVisualizzazione from "@/components/visualizzazione/RootVisualizzazione.vue";
import VisualizzazioneProssimiEventi from "@/components/visualizzazione/ProssimiEventi.vue";
import VisualizzazioneProssimiEventiConVideo from "@/components/visualizzazione/ProssimiEventiConVideo.vue";
import VisualizzazioneContenutoNonDisponibile from "@/components/visualizzazione/ContenutoNonDisponibile.vue";
import VisualizzazioneCanali from "@/components/visualizzazione/Canali.vue";
import VisualizzazioneStreaming from "@/components/visualizzazione/Streaming.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Manifestazioni,
    meta: { layout: Default },
  },
  {
    path: "/regolamenti",
    name: "regolamenti",
    component: Regolamenti,
    meta: { layout: Default },
  },
  {
    path: "/visualizzazione", // tipo = ['disciplina', 'manifestazione', 'canale', 'avvenimento']
    component: RootVisualizzazione,
    meta: { layout: FullContent },
    children: [
      { path: "", component: VisualizzazioneProssimiEventi },
      { path: "streaming", component: VisualizzazioneStreaming },
      { path: "video", component: VisualizzazioneProssimiEventiConVideo },
      { path: "canali", component: VisualizzazioneCanali },
      { path: "contenutonondisponibile", component: VisualizzazioneContenutoNonDisponibile },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
