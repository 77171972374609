<template>
  <b-skeleton-wrapper :loading="loading">
    <template #loading>
      <b-skeleton-table
        :rows="4"
        :columns="1"
        :table-props="{ bordered: true, striped: false }"
      ></b-skeleton-table>
    </template>

    <div>
      <b-list-group class="mb-2">
        <b-list-group-item
          v-b-toggle="'collapse-menu-' + subject"
          class="list-group-header"
        >
          <b-row>
            <b-col> {{ subject }} </b-col>
            <b-col cols="1">
              <b-icon stacked icon="chevron-down"></b-icon>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-collapse
          :id="'collapse-menu-' + subject"
          :visible="!isMobile"
        >
          <b-list-group-item @click="select(null)" :class="classSelected(null)">
            <b-row>
              <b-col> {{ $t(subject + ".tutto") }}</b-col>
            </b-row>
          </b-list-group-item>
          <b-list-group-item
            v-for="item in items"
            :key="item"
            @click="select(item)"
            :class="classSelected(item)"
          >
            <b-row>
              <b-col> {{ $t(subject + "." + item) }}</b-col>
              <b-col>
                <img
                  :class="subject"
                  style="float: right"
                  :src="getItemImage(item)"
                />
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-collapse>
      </b-list-group>
    </div>
  </b-skeleton-wrapper>
</template>

<script>
export default {
  props: ["isMobile", "loading", "title", "items", "selectedItem", "subject"],
  
  methods: {
    clearLoadingTimeInterval() {
      clearInterval(this.$_loadingTimeInterval);
      this.$_loadingTimeInterval = null;
    },
    select(idItem) {
      this.$emit("select", idItem);
    },
    classSelected(idItem) {
      if (idItem == this.selectedItem) return "active";
      return "";
    },
    getItemImage(item) {
      switch (this.subject) {
        case "nazione":
          return this.getMenuFlagImageUrl(item);
        case "disciplina":
          return this.getMenuSportImageUrl(item);
      }
    },
    getMenuFlagImageUrl(nazione) {
      var images = require.context("@/assets/bandiere/", false, /\.jpg$/);
      let flagImage = "";
      try {
        flagImage = images(`./${nazione}.jpg`);
      } catch (error) {
        flagImage = images(`./MONDO.jpg`);
      }
      return flagImage;
    },
    getMenuSportImageUrl(disciplina) {
      var images = require.context("@/assets/discipline/", false, /\.png$/);
      switch (disciplina) {
        case "Cyber Basket":
          disciplina = "basket";
          break;
        case "Cyber Calcio":
        default:
          disciplina = "calcio";
          break;
      }
      let flagImage = "";
      try {
        flagImage = images(`./${disciplina}.png`);
      } catch (error) {
        flagImage = images(`./calcio.png`);
      }
      return flagImage;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables";

.list-group {
  border-radius: 0 !important;
  border: 4px solid gray;
}
.list-group-header {
  background-color: $accent !important;
  border-color: $accent !important;
  border-radius: 0 !important;
  padding: 0.8rem 1.25rem !important;
  text-transform: uppercase;
  font-weight: bold;
}
img {
  &.nazione {
    height: 20px;
    border-radius: 25%;
    //border: 0.5px solid $primary-lighter;
  }
  &.disciplina {
    height: 20px;
  }
}

.active {
  font-weight: bold;
}
</style>