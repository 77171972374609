<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import FullContent from "@/layouts/FullContent.vue";
const default_layout = FullContent;
export default {
  computed: {
    layout() {
      return this.$route.meta.layout ? this.$route.meta.layout : default_layout;
    },
  },
  mounted() {
    if (!this._.isUndefined(localStorage.language))
      this.$i18n.locale = localStorage.language;
  },
};
</script>

<style lang="scss">
@import "@/sass/variables";
@import url("https://fonts.googleapis.com/css?family=Nunito");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@900&display=swap');

body {
  background-image: url("./assets/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

#content {
  background-color: $body-bg;
}

.list-group-item {
  cursor: pointer;
  padding: 0.5rem 1rem !important;
  border-radius: 0;
  &.active {
    background-color: $warn !important;
    border-color: $warn !important;
    color: $dark-text !important;
  }
  .row > div {
    padding: 5px;
  }
}

.bg-accent {
  background-color: $accent;
}
</style>

