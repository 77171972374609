<template>
  <div id="manifestazioniComponent" class="row my-4">
    <div class="col-md-3">
      <Giorno @select="selectGiorno($event)"></Giorno>

      <Menu
        subject="disciplina"
        :loading="loading"
        :isMobile="isMobile"
        :items="discipline"
        :selectedItem="selectedDisciplina"
        @select="selectDisciplina($event)"
      ></Menu>

      <Menu
        subject="nazione"
        :loading="loading"
        :isMobile="isMobile"
        :items="nazioni"
        :selectedItem="selectedNazione"
        @select="selectNazione($event)"
      ></Menu>

      <Funfact></Funfact>
    </div>

    <div class="col-md-9">
      <Eventi
        ref="eventiComponent"
        :loading="loading"
        :isMobile="isMobile"
        :manifestazioni="manifestazioni"
        :classifiche="classifiche"
      ></Eventi>
    </div>
  </div>
</template>


<script>
import Menu from "@/components/manifestazioni/Menu.vue";
import Eventi from "@/components/manifestazioni/Eventi.vue";
import Giorno from "@/components/manifestazioni/Giorno.vue";
import Funfact from "@/components/manifestazioni/Funfact.vue";

import api from "../services/api";

export default {
  data: () => {
    return {
      loading: false,
      interval: null,
      discipline: {},
      selectedDisciplina: null,
      nazioni: {},
      selectedNazione: null,
      manifestazioni: {},
      selectedManifestazione: null,
      avvenimenti: {},
      classifiche: [],
    };
  },
  components: {
    Menu,
    Eventi,
    Giorno,
    Funfact,
  },

  mounted() {
    var now = new Date();
    this.selectGiorno(
      now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate()
    );

    this.interval = setInterval(
      () => this.getListaManifestazioni(false),
      15 * 1000
    );
  },
  computed: {
    isMobile: () => window.innerWidth <= 760,
  },
  beforeDestroy() {
    if (this.interval != null) clearInterval(this.interval);
  },
  methods: {
    selectGiorno(data) {
      this.selectedGiorno = data;
      this.getListaManifestazioni();
    },
    selectDisciplina(disciplina) {
      this.selectedDisciplina = disciplina;
      this.filterAvvenimenti();
    },
    selectManifestazione(idManifestazione) {
      this.selectedManifestazione = idManifestazione;
    },
    selectNazione(nazione) {
      this.selectedNazione = nazione;
      this.filterAvvenimenti();
    },
    getListaManifestazioni(showLoading = true) {
      if (showLoading) this.loading = true;
      api.getAvvenimenti(
        {
          giorno: this.selectedGiorno,
        },
        (data) => {
          this.avvenimenti = data;
          this.manifestazioni = this._.chain(data)
            .map("tournament_id")
            .uniq()
            .value();

          this.discipline = this._.chain(data).map("sport_name").uniq().value();
          this.nazioni = this._.chain(data).map("country_name").uniq().value();
          this.filterAvvenimenti();
          if (showLoading) this.loading = false;
        }
      );
    },
    filterAvvenimenti() {
      let disciplina = this.selectedDisciplina;
      let nazione = this.selectedNazione;
      let filteredAvvenimenti = this.avvenimenti;

      if (!this._.isNull(disciplina))
        filteredAvvenimenti = this._.filter(filteredAvvenimenti, {
          sport_name: disciplina,
        });

      if (!this._.isNull(nazione))
        filteredAvvenimenti = this._.filter(filteredAvvenimenti, {
          country_name: nazione,
        });

      this.$refs["eventiComponent"].setAvvenimenti(filteredAvvenimenti);
    },
  },
};
</script>


<style lang="scss">
#manifestazioniComponent {
  min-height: 80vh;
  font-size: 12px;
}

@media (max-width: 760px) {
  #manifestazioniComponent {
    min-height: 40vh;
  }
}
</style>
