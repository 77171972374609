<template>
  <b-card no-body class="mt-3">
    <b-tabs pills card vertical nav-wrapper-class="w-25">
      <b-tab title="Cyber Basket" active>
        <b-card-text>
          <CyberBasket></CyberBasket>
        </b-card-text>
      </b-tab>
      <b-tab title="Cyber Football">
        <b-card-text>
          <CyberFootball></CyberFootball>
        </b-card-text>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import CyberBasket from "@/components/regolamenti/CyberBasket";
import CyberFootball from "@/components/regolamenti/CyberFootball";

export default {
  components: {
    CyberBasket,
    CyberFootball,
  },
};
</script>