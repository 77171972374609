<template>
  <div class="w-100">
    <div style="height: 100vh">
      <b-row class="d-flex align-content-center header">
        <b-col cols="3">
          <div style="margin-left: 5vh">{{ today | moment("H:mm") }}</div>
        </b-col>
        <b-col
          cols="6"
          class="text-center text-uppercase bold Catamaran"
          style="font-size: 6vh; line-height: 8vh"
        >
          EJOY CHANNELS
        </b-col>
        <b-col
          cols="3"
          class="text-right d-flex align-items-center justify-content-end"
        >
          <img
            src="@/assets/logo-scuro.png"
            style="height: 5vh; margin-right: 5vh"
          />
        </b-col>
      </b-row>
      <b-row class="body" no-gutters>
        <b-col
          cols="6"
          class="section"
          v-for="evento in event"
          :key="evento.id"
        >
          <div v-if="Object.keys(evento).length > 0">
            <b-row class="w-100 white-bg-rounded" no-gutters>
              <b-col cols="5" class="d-flex align-items-center">
                <div
                  v-if="evento.is_live"
                  class="badge live"
                  style="font-size: 3vh"
                >
                  LIVE
                </div>
                <div v-else class="badge prematch" style="font-size: 3vh">
                  PREMATCH
                </div>
              </b-col>
              <b-col cols="2" class="text-center">
                <span
                  style="font-size: 2.8vh; font-weight: bold; color: red"
                  v-if="evento.result_total"
                >
                  {{ evento.result_total.split(" ")[0] }}
                </span>
              </b-col>
              <b-col
                cols="5"
                class="d-flex align-items-center"
                style="justify-content: flex-end"
              >
                <div style="font-size: 2.8vh; font-weight: bold">
                  {{ evento.tournament_name }}
                </div>
              </b-col>
              <b-col cols="12" class="description">
                {{ evento.description }}
              </b-col>
            </b-row>

            <iframe
              :src="evento.channel_iframe_url"
              class="w-100"
              style="height: 44.2vh; margin: 2vh 0"
              frameBorder="0"
            ></iframe>
            <b-row class="w-100" no-gutters>
              <b-col
                cols="6"
                v-for="(group, index) in marketOutcomeIds[evento.sport_id]"
                :key="index"
              >
                <b-table-simple style="background-color: white">
                  <b-thead>
                    <b-tr style="background-color: #d3d3d333">
                      <b-th :colspan="group.length">
                        {{ getMarketLabel(evento.markets, group[0].marketId) }}
                      </b-th>
                    </b-tr>
                    <b-tr>
                      <b-th v-for="(mark, i) in group" :key="i">
                        {{ getOutcomeLabel(evento.markets, mark) }}
                      </b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr>
                      <b-td
                        v-for="(mark, i) in group"
                        :key="i"
                        :style="mark.param ? 'font-weight: normal;' : ''"
                        class="quota"
                        :class="classMarketOutcome(evento, mark)"
                      >
                        <span class="arrow-up"></span>
                        {{ getMarketOutcome(evento.markets, mark) }}
                        <span class="arrow-down"></span>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </div>
          <div v-else class="empty">
            <img src="@/assets/logo-scuro.png" />
            <br />
            <span>L'evento live sta per iniziare...</span>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";

const canali = [1, 2];
const marketOutcomeIds = {
  1: [
    [
      {
        marketId: 3,
        periodId: 1,
        outcomeId: 1,
      },
      {
        marketId: 3,
        periodId: 1,
        outcomeId: 2,
      },
      {
        marketId: 3,
        periodId: 1,
        outcomeId: 3,
      },
    ],
    [
      {
        marketId: 10055,
        periodId: 1,
        outcomeId: 37,
        param: true,
      },
      {
        marketId: 10055,
        periodId: 1,
        outcomeId: 37,
      },
      {
        marketId: 10055,
        periodId: 1,
        outcomeId: 38,
      },
    ],
    /* [
      {
        marketId: 8333,
        periodId: 1,
        outcomeId: 7,
      },
      {
        marketId: 8333,
        periodId: 1,
        outcomeId: 8,
      },
      {
        marketId: 8333,
        periodId: 1,
        outcomeId: 9,
      },
    ],
    [
      {
        marketId: 19,
        periodId: 1,
        outcomeId: 10,
      },
      {
        marketId: 19,
        periodId: 1,
        outcomeId: 11,
      },
    ],*/
  ],
  2: [
    [
      {
        marketId: 3,
        periodId: 1,
        outcomeId: 1,
      },
      {
        marketId: 3,
        periodId: 1,
        outcomeId: 2,
      },
      {
        marketId: 3,
        periodId: 1,
        outcomeId: 3,
      },
    ],
    [
      {
        marketId: 10055,
        periodId: 0,
        outcomeId: 37,
        param: true,
      },
      {
        marketId: 10055,
        periodId: 0,
        outcomeId: 37,
      },
      {
        marketId: 10055,
        periodId: 0,
        outcomeId: 38,
      },
    ],

    /* [
      {
        marketId: 2,
        periodId: 0,
        outcomeId: 39,
      },
      {
        marketId: 2,
        periodId: 0,
        outcomeId: 40,
      },
    ], 
    [
      {
        marketId: 19,
        periodId: 0,
        outcomeId: 10,
      },
      {
        marketId: 19,
        periodId: 0,
        outcomeId: 11,
      },
    ],*/
  ],
};

export default {
  data: () => {
    return {
      event: {},
      interval: null,
      today: new Date(),
      todayInterval: null,
      marketOutcomeIds: marketOutcomeIds,
      mappedMarkets: [],
    };
  },
  mounted() {
    this.init();
    this.interval = setInterval(() => this.init(), 10 * 1000);
    this.todayInterval = setInterval(() => {
      this.setToday();
    }, 60 * 1000);
  },
  beforeDestroy() {
    if (this.interval != null) clearInterval(this.interval);
    if (this.todayInterval != null) clearInterval(this.todayInterval);
  },
  methods: {
    setToday() {
      this.today = new Date();
    },
    init() {
      var query = { limite: 1 };
      canali.forEach((canale) => {
        query.canale = canale;
        api.getAvvenimenti(query, (data) => {
          if (data.length > 0) this.$set(this.event, canale, data[0]);
          else this.$set(this.event, canale, {});
        });
      });
    },
    getMarketLabel(markets, marketId) {
      return markets[marketId] ? markets[marketId].market_name : "";
    },
    getOutcomeLabel(markets, marketOutcomeId) {
      if (marketOutcomeId.param) return "";

      if (
        markets[marketOutcomeId.marketId] &&
        markets[marketOutcomeId.marketId].periods[marketOutcomeId.periodId] &&
        markets[marketOutcomeId.marketId].periods[marketOutcomeId.periodId]
          .outcomes[marketOutcomeId.outcomeId]
      )
        var outcome =
          markets[marketOutcomeId.marketId].periods[marketOutcomeId.periodId]
            .outcomes[marketOutcomeId.outcomeId];
      if (outcome) return outcome.outcome_list_name;

      return "";
    },
    getMarketOutcome(markets, marketOutcomeId) {
      if (
        markets[marketOutcomeId.marketId] &&
        markets[marketOutcomeId.marketId].periods[marketOutcomeId.periodId] &&
        markets[marketOutcomeId.marketId].periods[marketOutcomeId.periodId]
          .outcomes[marketOutcomeId.outcomeId]
      )
        var outcome =
          markets[marketOutcomeId.marketId].periods[marketOutcomeId.periodId]
            .outcomes[marketOutcomeId.outcomeId];

      if (outcome)
        return marketOutcomeId.param
          ? outcome.outcome_param_1
          : outcome.outcome_odd.toFixed(2);

      return "-";
    },
    classMarketOutcome(event, marketOutcomeId) {
      if (marketOutcomeId.param) return "";
      var outcomeOdd = this.getMarketOutcome(event.markets, marketOutcomeId);
      if (outcomeOdd == "-") return "";
      var key =
        event.id +
        "_" +
        marketOutcomeId.marketId +
        "_" +
        marketOutcomeId.periodId +
        "_" +
        marketOutcomeId.outcomeId;
      var returnClass = "";
      if (this.mappedMarkets[key]) {
        if (this.mappedMarkets[key] > outcomeOdd) {
          returnClass = "down";
        } else if (this.mappedMarkets[key] < outcomeOdd) {
          returnClass = "up";
        }
      }
      this.mappedMarkets[key] = outcomeOdd;
      return returnClass;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables";

.Catamaran {
  font-family: "Catamaran", sans-serif;
}

@keyframes blink {
  0% {
    background-color: $danger;
  }
  50% {
    background-color: $danger-lighter;
  }
  100% {
    background-color: $danger;
  }
}
@-webkit-keyframes blink {
  0% {
    background-color: $danger;
  }
  50% {
    background-color: $danger-lighter;
  }
  100% {
    background-color: $danger;
  }
}

.white-bg-rounded {
  padding: 1vh;
  background-color: white;
  //border-radius: 50px;

  border: 2px solid #b9bdbd;
}

.badge {
  font-size: 2.5vh;
  padding: 0.5vh 1vh;
  &.live {
    background-color: $accent;
    color: $primary;
    -webkit-animation: blink 2s ease infinite;
    -moz-animation: blink 2s ease infinite;
    animation: blink 2s ease infinite;
  }
  &.prematch {
    background-color: $primary;
    color: white;
  }
}

.header {
  background-color: $accent-lighter-50;
  background: -moz-linear-gradient(
    90deg,
    $accent-lighter-30 0%,
    $accent-lighter-50 40%,
    $accent-lighter-50 60%,
    white 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    $accent-lighter-30 0%,
    $accent-lighter-50 40%,
    $accent-lighter-50 60%,
    white 100%
  );
  background: linear-gradient(
    90deg,
    $accent-lighter-30 0%,
    $accent-lighter-50 40%,
    $accent-lighter-50 60%,
    white 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$accent-lighter-30,endColorstr="#ffffff",GradientType=1);
  height: 10vh;
  font-size: 5vh;
  font-weight: bold;
  border-bottom: 0.5vh solid $primary;
}

.body {
  background-color: $accent-lighter-50;
  height: 90vh;
  border-bottom: 0.5vh dotted $accent;
  position: relative;

  .section {
    padding: 2vh;
    &:nth-child(1) {
      border-right: 0.5vh dotted $accent;
    }
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    margin: 1vh 0;
  }

  .description {
    text-transform: uppercase;
    font-size: 3vh;
    font-weight: bold;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  table {
    text-align: center;
    border: 2px solid #b9bdbd;
    margin-bottom: 0;
    &:nth-child(1) {
      padding: 0 0.5vh 0 0;
    }

    thead th {
      font-size: 2.4vh;
      text-transform: uppercase;
    }

    td {
      font-size: 3vh;
      font-weight: bold;
    }

    thead th,
    td {
      border-top: 0;
      height: 7vh;
      border-bottom: 2px solid $primary-lighter;
      //border-left: 2px solid $primary;
      vertical-align: middle;
    }
  }
}

.lettura-facilitata {
  &:nth-child(odd) {
    background-color: $accent-lighter;
  }
  /*&:nth-child(even) {
    background-color: $accent-lighter;
  }*/
}
.empty {
  height: 80vh;
  background-color: $accent-lighter;
  font-size: 5vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    height: 8vh;
  }
}

.quota {
  position: relative;
  &.up {
    .arrow-down {
      display: none;
    }

    .arrow-up {
      display: block;
    }
  }

  &.down {
    .arrow-down {
      display: block;
    }

    .arrow-up {
      display: none;
    }
  }

  .arrow-up {
    width: 0;
    height: 0;
    border-left: 1vh solid transparent;
    border-right: 1vh solid transparent;
    border-bottom: 1vh solid green;
    display: none;
    position: absolute;
    top: 45%;
    right: 8px;
  }

  .arrow-down {
    width: 0;
    height: 0;
    border-left: 1vh solid transparent;
    border-right: 1vh solid transparent;
    border-top: 1vh solid red;
    display: none;
    position: absolute;
    top: 45%;
    right: 8px;
  }
}
</style>

