<template>
  <div id="eventiComponent">
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-skeleton-table
          hide-header
          :rows="1"
          :columns="4"
          :table-props="{ bordered: true, striped: false }"
        ></b-skeleton-table>
        <b-skeleton-table
          hide-header
          :rows="10"
          :columns="1"
          :table-props="{ bordered: true, striped: false }"
        ></b-skeleton-table>
      </template>
      <div v-if="!_.isEmpty(manifestazioni)">
        <b-nav tabs fill>
          <b-nav-item
            v-for="stato in stati"
            :key="stato.label"
            :class="[
              stato.label,
              classSelectedStato(stato.label),
              isMobile ? 'w-50' : 'w-25',
            ]"
            @click="selectStato(stato)"
          >
            {{ $t("evento.stato." + stato.label) }}
          </b-nav-item>
        </b-nav>
        <div
          v-for="(eventi, manifestazione) in mappedEventi"
          :key="manifestazione"
        >
          <div
            v-if="showManifestazione(eventi)"
            v-b-toggle="'manifestazione-' + manifestazione"
            class="header-manifestazione"
            :class="statusManifestazione(eventi)"
          >
            <b-row :class="isMobile ? 'no-gutters' : ''">
              <b-col
                cols="4"
                md="1"
                :class="isMobile ? 'justify-content-center' : ''"
              >
                {{ eventi[0].begin | moment("H:mm") }}
              </b-col>
              <b-col cols="4" md="2">
                {{ eventi[0].sport_name }}
              </b-col>
              <b-col cols="4" class="d-block d-md-none text-center">
                <img
                  class="flag"
                  :src="getFlagImageUrl(eventi[0].country_name)"
                />
              </b-col>
              <b-col
                cols="12"
                md="8"
                :class="isMobile ? 'justify-content-center' : ''"
              >
                <b>{{ eventi[0].tournament_name }}</b>
              </b-col>
              <b-col cols="3" md="1" class="d-none d-md-block">
                <img
                  class="flag"
                  style="float: right"
                  :src="getFlagImageUrl(eventi[0].country_name)"
                />
              </b-col>
            </b-row>
          </div>
          <b-collapse
            v-if="showManifestazione(eventi)"
            :id="'manifestazione-' + manifestazione"
            :visible="toggleVisibilityCollapseManifestazione(manifestazione)"
            @hide="
              $set(visibilityCollapseManifestazione, manifestazione, false)
            "
            @show="$set(visibilityCollapseManifestazione, manifestazione, true)"
          >
            <b-list-group-item v-for="evento in eventi" :key="evento.id">
              <b-row
                class="w-100 m-0"
                v-b-toggle="'streaming-evento-' + evento.id"
                @click.stop
              >
                <b-col cols="4" md="2" class="text-center">
                  <span v-if="evento.is_live" class="live-label"> LIVE </span>
                  <span v-else>
                    {{ evento.begin | moment("H:mm") }}
                  </span></b-col
                >
                <b-col cols="4" class="d-block d-md-none"> </b-col>

                <b-col
                  cols="12"
                  md="6"
                  :class="isMobile ? 'text-center' : 'text-left'"
                >
                  {{ evento.description }}
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="text-center"
                  v-if="
                    evento.status == 4 || (evento.status == 2 && evento.is_live)
                  "
                  :style="evento.is_live ? 'color: red' : ''"
                >
                  <b> {{ evento.result_total }} </b>
                </b-col>
                <b-col
                  v-if="evento.status == 2 && evento.is_live"
                  cols="12"
                  md="1"
                  class="streaming"
                >
                  <img src="@/assets/streaming.png" />
                </b-col>
              </b-row>
              <b-collapse
                v-if="evento.status == 2 && evento.is_live"
                :id="'streaming-evento-' + evento.id"
                class="streaming col-12"
                @hide="$set(videoFlag, evento.id, false)"
                @show="$set(videoFlag, evento.id, true)"
              >
                <b-row>
                  <b-col>
                    <iframe
                      v-if="videoFlag[evento.id] && visibilityCollapseManifestazione[manifestazione]"
                      :src="evento.channel_iframe_url"
                      class="w-75"
                      style="height: 30vh"
                      frameBorder="0"
                    ></iframe>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-list-group-item>
          </b-collapse>
        </div>
      </div>
      <div v-else>
        {{ $tc("evento.contatore", Object.keys(avvenimenti).length) }}
      </div>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      videoFlag: {},
      visibilityCollapseManifestazione: {},
      avvenimenti: {},
      mappedEventi: {},
      selectedStato: { label: "Scheduled", value: "2" },
      stati: [
        { label: "All", value: "" },
        { label: "Scheduled", value: "2" },
        { label: "Live", value: "2" },
        { label: "Finished", value: "4" },
      ],
      collapses: {},
    };
  },
  props: ["loading", "isMobile", "manifestazioni", "classifiche"],
  computed: {
    hostname: () => window.location.hostname,
  },
  methods: {
    toggleVisibilityCollapseManifestazione(idManifestazione) {
      var eventi = this.mappedEventi[idManifestazione];
      var now = this.$moment();
      var nowPlus = this.$moment().add(1, "h");
      var found = this._.find(eventi, (evento) => {
        var eventoStart = this.$moment(evento.begin);
        if (evento.status == "Live") return true;
        return eventoStart >= now && eventoStart <= nowPlus;
      });
      return found ? true : false;
    },
    mapEventi() {
      this.mappedEventi = this._.groupBy(this.avvenimenti, "tournament_id");
    },
    selectStato(stato) {
      this.selectedStato = stato;
      this.mapEventi();
    },
    classSelectedStato(stato) {
      if (stato == this.selectedStato.label) return "active";
      return "";
    },
    statusManifestazione(eventi) {
      if (
        this._.find(eventi, function (evento) {
          return evento.is_live == 1;
        })
      )
        return "live";
      else if (
        this._.find(eventi, function (evento) {
          return evento.status == 2;
        })
      )
        return "scheduled";
      else return "finished";
    },
    getFlagImageUrl(nazione) {
      var images = require.context("@/assets/bandiere/", false, /\.jpg$/);
      let flagImage = "";
      try {
        flagImage = images(`./${nazione}.jpg`);
      } catch (error) {
        flagImage = images(`./MONDO.jpg`);
      }
      return flagImage;
    },
    showManifestazione(eventi) {
      if (eventi)
        switch (this.selectedStato.label) {
          case "Live":
            return this.statusManifestazione(eventi) == "live";
          case "Scheduled":
            return this.statusManifestazione(eventi) != "finished";
          case "Finished":
            return this.statusManifestazione(eventi) == "finished";
          default:
            return true;
        }
      else return false;
    },
    setAvvenimenti(avvenimenti) {
      this.avvenimenti = avvenimenti;
      this.mapEventi();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables";

#eventiComponent {
  border-radius: 0 !important;
  border: 4px solid gray;
  .nav.nav-tabs {
    border-bottom: 2px solid gray;

    text-transform: uppercase;
    .nav-item {
      border: 2px solid gray;
      border-top: 0;
      color: $dark-primary-text;
      font-weight: bold;

      &:first-child {
        border-left: 0;
      }
      &:last-child {
        border-right: 0;
      }

      &.active {
        filter: brightness(80%);
      }

      &.All {
        background-color: $light-text;
      }
      &.Scheduled {
        background-color: $warn;
      }
      &.Live {
        background-color: $accent;
      }
      &.Finished {
        background-color: $primary;
        a {
          color: $light-text;
        }
      }
      .nav-link {
        &:focus,
        &:hover {
          border: inherit;
          border-radius: 0;
          filter: brightness(80%);
        }
      }
    }
  }

  .header-manifestazione {
    border-radius: 0;
    border-top: 1px solid gray;
    .row {
      width: 96%;
      margin: 0 2%;
      & > div {
        padding: 15px;
        display: flex;
        align-items: center;
        &.ranking {
          justify-content: center;
          background-color: $warn;
          padding: 6px 10px;
          margin-top: 6px;
          img {
            height: 30px;
          }
        }
      }

      @media (max-width: 760px) {
        & > div {
          padding: 8px;
        }
      }
    }
    &.scheduled {
      background-color: $warn;
    }
    &.live {
      background-color: $accent;
    }
    &.finished {
      background-color: $primary-opacity-80;
      color: $light-text;
    }
  }
  .list-group-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:nth-child(even) {
      background-color: whitesmoke;
    }
    .live-label {
      padding: 5px 15px;
      background-color: $accent;
    }
    .streaming {
      text-align: center;
      padding: 0;
      img {
        height: 28px;
      }
    }
  }
  .collapse.ranking {
    border: 4px solid $warn;
  }
  img.flag {
    height: 20px;
    border-radius: 25%;
  }
}
</style>