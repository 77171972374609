<template>
  <div>
    <b-form-datepicker
      class="mb-2"
      v-model="value"
      hide-header
      start-weekday="1"
      :min="min"
      :max="max"
      :locale="$t('locale')"
      @input="select()"
    ></b-form-datepicker>
  </div>
</template>

<script>
export default {
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // minDate = last month
    const minDate = new Date(today);
    minDate.setMonth(minDate.getMonth() - 1);
    // maxDate = today
    const maxDate = new Date(today);

    return {
      value: today,
      min: minDate,
      max: maxDate,
    };
  },
  methods: {
    select() {
      this.$emit("select", this.value);
    },
  },
};
</script>


<style lang="scss" scoped>
@import "@/sass/variables";
.b-form-datepicker {
  border-radius: 0;
  border: 4px solid gray;
  padding: 0;
  margin: 0;
  height: 50px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
</style>