import ajaxCall from "./ajaxCall";
/*
let DEVELOPMENT = process.env.NODE_ENV === "development";
let endpoint = DEVELOPMENT
  ? "http://ejoymanagerbackend.test/api/esports/live/"
  : "https://api.ejoyesports.com/api/esports/live/";*/
let endpoint = "https://api.ejoyesports.com/api/esports/live/";
export default {
  getAvvenimenti(data, cb) {
    ajaxCall
      .sendGet(endpoint + "avvenimenti", data)
      .then((response) => cb(response.data));
  },

  getAvvenimento(idEvento, cb) {
    ajaxCall
      .sendGet(endpoint + "avvenimenti/" + idEvento)
      .then((response) => cb(response.data));
  },
};
