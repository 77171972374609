import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import i18n from "./i18n";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import axios from "axios";
import VueAxios from "vue-axios";
import Embed from "v-video-embed";
import { BootstrapVueIcons } from "bootstrap-vue";

const moment = require("moment-timezone");
//require("moment/locale/it");
moment.tz.setDefault("UTC");
Vue.config.productionTip = false;

Vue.use(VueLodash, { lodash: lodash });
Vue.use(VueAxios, axios);

Vue.use(require("vue-moment"), {
  moment,
});
Vue.use(Embed);
Vue.use(BootstrapVueIcons);
/*Vue.filter('formatDataOra', (value) => {
  return moment(value).format("DD/MM H:mm");
});

Vue.filter('formatOra', (value) => {
  return moment(value).format("H:mm");
});*/

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
