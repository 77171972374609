<template>
  <div class="container">
    <Navbar></Navbar>
    <div id="content" class="row default-layout">
      <div class="col">
        <slot />
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    Footer,
  },
};
</script>

<style lang="scss">

#content.default-layout {
  min-height: 80vh;
}
</style>