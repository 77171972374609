<template>
  <div class="w-100">
    <div v-if="Object.keys(mainEvent).length > 0" style="height: 100vh">
      <b-row class="d-flex align-content-center header">
        <b-col cols="3">
          <div style="margin-left: 5vh">
            {{ today | moment("timezone", "Europe/Rome", "H:mm") }}
          </div>
        </b-col>
        <b-col
          cols="6"
          class="text-center text-uppercase bold Catamaran"
          style="font-size: 6vh; line-height: 8vh"
        >
          {{ mainEvent.sport_name }}
        </b-col>
        <b-col
          cols="3"
          class="text-right d-flex align-items-center justify-content-end"
        >
          <img
            src="@/assets/logo-scuro.png"
            style="height: 5vh; margin-right: 5vh"
        /></b-col>
      </b-row>
      <b-row class="body">
        <b-col cols="12" style="padding: 0; margin: 0">
          <b-row class="w-100 white-bg">
            <b-col cols="2" class="d-flex align-items-center">
              <div
                v-if="mainEvent.is_live"
                class="badge live"
                style="font-size: 3vh"
              >
                LIVE
              </div>
              <div v-else class="badge prematch" style="font-size: 3vh">
                {{
                  mainEvent.begin | moment("timezone", "Europe/Rome", "H:mm")
                }}
              </div>
            </b-col>
            <b-col cols="8" class="description">
              {{ mainEvent.description }}
            </b-col>
            <b-col
              cols="2"
              class="d-flex align-items-center"
              style="justify-content: flex-end"
            >
              <div class="tournament-name">
                {{ mainEvent.tournament_name }}
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="5" class="white-bg">
          <div>
            <div class="score">
              <span v-if="mainEvent.result_total">
                <span>{{ mainEvent.result_total.split(" ")[0] }}</span>
              </span>
              <span v-else>0:0</span>
            </div>
            <div class="sub-score">
              <span v-if="mainEvent.result_total">
                {{ mainEvent.result_total.split(" ")[1] }}
              </span>
            </div>
          </div>
        </b-col>

        <b-col>
          <b-row class="w-100 no-gutters">
            <b-col
              cols="6"
              v-for="(group, index) in marketOutcomeIds[mainEvent.sport_id]"
              :key="index"
              style="padding: 0 0 0 1vh"
            >
              <b-table-simple style="background-color: white">
                <b-thead>
                  <b-tr style="background-color: #d3d3d333">
                    <b-th :colspan="group.length">
                      {{ getMarketLabel(mainEvent.markets, group[0].marketId) }}
                    </b-th>
                  </b-tr>
                  <b-tr>
                    <b-th v-for="(mark, i) in group" :key="i">
                      {{ getOutcomeLabel(mainEvent.markets, mark) }}
                    </b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td
                      v-for="(mark, i) in group"
                      :key="i"
                      :style="mark.param ? 'font-weight: normal;' : ''"
                      class="quota"
                      :class="classMarketOutcome(mainEvent, mark)"
                    >
                      <span class="arrow-up"></span>
                      {{ getMarketOutcome(mainEvent.markets, mark) }}
                      <span class="arrow-down"></span>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="footer no-gutters">
        <b-col
          v-for="event in secondaryEvents"
          :key="event.id"
          class="footer-border"
        >
          <b-row class="begin">
            <b-col cols="4">
              <div v-if="event.is_live" class="badge live">LIVE</div>
              <div v-else class="badge prematch">
                {{ event.begin | moment("timezone", "Europe/Rome", "H:mm") }}
              </div>
            </b-col>
            <b-col cols="8" class="tournament">
              {{ event.tournament_name | truncateString(20) }}
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="description">
              {{ event.description }}
            </b-col>

            <b-col cols="12" class="outcomes">
              <b-table-simple>
                <b-thead>
                  <b-tr class="lettura-facilitata">
                    <b-th
                      class="market-label"
                      :colspan="marketOutcomeIds[event.sport_id][0].length"
                    >
                      ESITO FINALE 1X2
                    </b-th>
                  </b-tr>
                  <b-tr class="lettura-facilitata">
                    <b-th
                      class="outcome-label"
                      v-for="(mark, i) in marketOutcomeIds[event.sport_id][0]"
                      :key="i"
                    >
                      {{ mark.label }}
                    </b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr class="lettura-facilitata">
                    <b-td
                      class="outcome"
                      v-for="(mark, i) in marketOutcomeIds[event.sport_id][0]"
                      :key="i"
                    >
                      {{ getMarketOutcome(event.markets, mark) }}
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div v-else class="empty">
      <img src="@/assets/logo-scuro.png" />
      <br />
      <span>L'evento live sta per iniziare...</span>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";

const defaultType = "disciplina";
const defaultId = 1;
const defaultLimit = 5;
const marketOutcomeIds = {
  1: [
    [
      {
        marketId: 3,
        periodId: 1,
        outcomeId: 1,
        label: "1",
      },
      {
        marketId: 3,
        periodId: 1,
        outcomeId: 2,
        label: "X",
      },
      {
        marketId: 3,
        periodId: 1,
        outcomeId: 3,
        label: "2",
      },
    ],
    [
      {
        marketId: 10055,
        periodId: 1,
        outcomeId: 37,
        param: true,
      },
      {
        marketId: 10055,
        periodId: 1,
        outcomeId: 37,
      },
      {
        marketId: 10055,
        periodId: 1,
        outcomeId: 38,
      },
    ],
    [
      {
        marketId: 8333,
        periodId: 1,
        outcomeId: 7,
      },
      {
        marketId: 8333,
        periodId: 1,
        outcomeId: 8,
      },
      {
        marketId: 8333,
        periodId: 1,
        outcomeId: 9,
      },
    ],
    [
      {
        marketId: 19,
        periodId: 1,
        outcomeId: 10,
      },
      {
        marketId: 19,
        periodId: 1,
        outcomeId: 11,
      },
    ],
  ],
  2: [
    [
      {
        marketId: 3,
        periodId: 1,
        outcomeId: 1,
        label: "1",
      },
      {
        marketId: 3,
        periodId: 1,
        outcomeId: 2,
        label: "X",
      },
      {
        marketId: 3,
        periodId: 1,
        outcomeId: 3,
        label: "2",
      },
    ],
    [
      {
        marketId: 2,
        periodId: 0,
        outcomeId: 39,
      },
      {
        marketId: 2,
        periodId: 0,
        outcomeId: 40,
      },
    ],
    [
      {
        marketId: 10055,
        periodId: 0,
        outcomeId: 37,
        param: true,
      },
      {
        marketId: 10055,
        periodId: 0,
        outcomeId: 37,
      },
      {
        marketId: 10055,
        periodId: 0,
        outcomeId: 38,
      },
    ],
    [
      {
        marketId: 19,
        periodId: 0,
        outcomeId: 10,
      },
      {
        marketId: 19,
        periodId: 0,
        outcomeId: 11,
      },
    ],
  ],
};

export default {
  filters: {
    truncateString(str, leng) {
      if (str.length > leng) {
        return str.substring(0, leng) + "...";
      }
      return str;
    },
  },
  data: () => {
    return {
      query: { [defaultType]: defaultId },
      mainEvent: {},
      secondaryEvents: {},
      interval: null,
      today: new Date(),
      todayInterval: null,
      marketOutcomeIds: marketOutcomeIds,
      mappedMarkets: [],
    };
  },
  mounted() {
    this.query = this.$route.query;
    this.query.limite = defaultLimit;
    this.init();
    this.interval = setInterval(() => this.init(), 10 * 1000);
    this.todayInterval = setInterval(() => {
      this.setToday();
    }, 60 * 1000);
  },
  beforeDestroy() {
    if (this.interval != null) clearInterval(this.interval);
    if (this.todayInterval != null) clearInterval(this.todayInterval);
  },
  methods: {
    setToday() {
      this.today = new Date();
    },
    init() {
      if (this.type == "avvenimento")
        api.getAvvenimento(this.id, (res) => {
          this.mainEvent = res;
        });
      else
        api.getAvvenimenti(this.query, (data) => {
          if (data.length > 1) {
            this.mainEvent = data.shift();
            this.secondaryEvents = data;
          } else if (data.length == 1) {
            this.mainEvent = data[0];
            this.secondaryEvents = [];
          } else {
            this.mainEvent = {};
            this.secondaryEvents = [];
          }
        });
    },
    getMarketLabel(markets, marketId) {
      return markets[marketId] ? markets[marketId].market_name : "";
    },
    getOutcomeLabel(markets, marketOutcomeId) {
      if (marketOutcomeId.param) return "";

      if (
        markets[marketOutcomeId.marketId] &&
        markets[marketOutcomeId.marketId].periods[marketOutcomeId.periodId] &&
        markets[marketOutcomeId.marketId].periods[marketOutcomeId.periodId]
          .outcomes[marketOutcomeId.outcomeId]
      )
        var outcome =
          markets[marketOutcomeId.marketId].periods[marketOutcomeId.periodId]
            .outcomes[marketOutcomeId.outcomeId];
      if (outcome) return outcome.outcome_list_name;

      return "";
    },
    getMarketOutcome(markets, marketOutcomeId) {
      if (
        markets[marketOutcomeId.marketId] &&
        markets[marketOutcomeId.marketId].periods[marketOutcomeId.periodId] &&
        markets[marketOutcomeId.marketId].periods[marketOutcomeId.periodId]
          .outcomes[marketOutcomeId.outcomeId]
      )
        var outcome =
          markets[marketOutcomeId.marketId].periods[marketOutcomeId.periodId]
            .outcomes[marketOutcomeId.outcomeId];

      if (outcome)
        return marketOutcomeId.param
          ? outcome.outcome_param_1
          : outcome.outcome_odd.toFixed(2);

      return "-";
    },
    classMarketOutcome(event, marketOutcomeId) {
      if (marketOutcomeId.param) return "";
      var outcomeOdd = this.getMarketOutcome(event.markets, marketOutcomeId);
      if (outcomeOdd == "-") return "";
      var key =
        event.id +
        "_" +
        marketOutcomeId.marketId +
        "_" +
        marketOutcomeId.periodId +
        "_" +
        marketOutcomeId.outcomeId;
      var returnClass = "";
      if (this.mappedMarkets[key]) {
        if (this.mappedMarkets[key] > outcomeOdd) {
          returnClass = "down";
        } else if (this.mappedMarkets[key] < outcomeOdd) {
          returnClass = "up";
        }
      }
      this.mappedMarkets[key] = outcomeOdd;
      return returnClass;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/variables";

.Catamaran {
  font-family: "Catamaran", sans-serif;
}

@keyframes blink {
  0% {
    background-color: $danger;
  }
  50% {
    background-color: $danger-lighter;
  }
  100% {
    background-color: $danger;
  }
}
@-webkit-keyframes blink {
  0% {
    background-color: $danger;
  }
  50% {
    background-color: $danger-lighter;
  }
  100% {
    background-color: $danger;
  }
}

.white-bg {
  padding: 1vh;
  background-color: white;
  //border-radius: 50px;

  border: 2px solid #b9bdbd;
}

.badge {
  font-size: 2.5vh;
  padding: 0.5vh 1vh;
  &.live {
    background-color: $accent;
    color: $primary;
    -webkit-animation: blink 2s ease infinite;
    -moz-animation: blink 2s ease infinite;
    animation: blink 2s ease infinite;
  }
  &.prematch {
    background-color: $primary;
    color: white;
  }
}

.header {
  background-color: $accent-lighter-50;
  background: -moz-linear-gradient(
    90deg,
    $accent-lighter-30 0%,
    $accent-lighter-50 40%,
    $accent-lighter-50 60%,
    white 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    $accent-lighter-30 0%,
    $accent-lighter-50 40%,
    $accent-lighter-50 60%,
    white 100%
  );
  background: linear-gradient(
    90deg,
    $accent-lighter-30 0%,
    $accent-lighter-50 40%,
    $accent-lighter-50 60%,
    white 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=$accent-lighter-30,endColorstr="#ffffff",GradientType=1);
  height: 10vh;
  font-size: 5vh;
  font-weight: bold;
  border-bottom: 0.5vh solid $primary;
}

.body {
  background-color: $accent-lighter-50;
  height: 60vh;
  border-bottom: 0.5vh dotted $accent;
  position: relative;
  padding: 2vh 4vh;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    margin: 1vh 0;
  }
  .score {
    font-weight: bold;
    color: $accent;
    font-size: 25vh;
    line-height: 25vh;
  }

  .sub-score {
    font-size: 5vh;
    font-weight: bold;
    color: $accent;
    text-align: center;
  }

  .description {
    text-transform: uppercase;
    font-size: 4vh;
    line-height: 4vh;
    font-weight: bold;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .tournament-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 2.8vh;
    font-weight: bold;
  }

  .col-6:nth-child(1) table {
    margin-bottom: 1vh;
  }

  table {
    text-align: center;
    border: 2px solid #b9bdbd;
    margin-bottom: 0;

    thead th {
      font-size: 2.4vh;
      text-transform: uppercase;
    }

    td {
      font-size: 3vh;
      font-weight: bold;
    }

    thead th,
    td {
      border-top: 0;
      height: 7vh;
      border-bottom: 2px solid $primary-lighter;
      //border-left: 2px solid $primary;
      vertical-align: middle;
    }
  }
}

.footer {
  background-color: $accent-lighter-50;
  padding-top: 1vh;
  height: 32vh;
  text-align: center;

  .footer-border {
    border: 1px solid black;
    border-top: 0;
    &:first-child {
      border-left: 0;
    }

    &:last-child {
      border-right: 0;
    }
  }
  .begin {
    font-size: 3vh;
    height: 4.5vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2vh;
    font-weight: bold;
  }
  .tournament {
    border: 1px solid black;
    border-radius: 15px;
    font-size: 2.5vh;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .description {
    color: black;
    border-bottom: 1px solid black;
    font-size: 2.5vh;
    font-weight: bold;
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .outcomes {
    height: 15vh;
    .market-label,
    .outcome-label,
    .outcome {
      height: 5vh;
      font-size: 2vh;
      border-bottom: 1px solid black;
      font-weight: bold;
    }

    .market-label {
      height: 5vh;
      text-transform: uppercase;
    }
  }
}

.lettura-facilitata {
  &:nth-child(odd) {
    background-color: $accent-lighter;
  }
  /*&:nth-child(even) {
    background-color: $accent-lighter;
  }*/
}
.empty {
  height: 100vh;
  background-color: $accent-lighter;
  font-size: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    height: 10vh;
  }
}

.quota {
  position: relative;
  &.up {
    .arrow-down {
      display: none;
    }

    .arrow-up {
      display: block;
    }
  }

  &.down {
    .arrow-down {
      display: block;
    }

    .arrow-up {
      display: none;
    }
  }

  .arrow-up {
    width: 0;
    height: 0;
    border-left: 1vh solid transparent;
    border-right: 1vh solid transparent;
    border-bottom: 1vh solid green;
    display: none;
    position: absolute;
    top: 45%;
    right: 8px;
  }

  .arrow-down {
    width: 0;
    height: 0;
    border-left: 1vh solid transparent;
    border-right: 1vh solid transparent;
    border-top: 1vh solid red;
    display: none;
    position: absolute;
    top: 45%;
    right: 8px;
  }
}
</style>

